<template>
  <div class="svg-container">
    <div class="svg-group s-svg">
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 620.5 194.5"
        enable-background="new 0 0 620.5 194.5"
        xml:space="preserve"
        class="s-svg"
      >
        <defs>
          <linearGradient id="gradient" x1="0" x2="0" y1="1" y2="0">
            <stop offset="0%" stop-color="#fe133f" />
            <stop offset="28%" stop-color="#b02038" />
            <stop offset="53%" stop-color="#aa1923" />
            <stop offset="100%" stop-color="#631f26" />
          </linearGradient>
          <linearGradient id="inv-gradient" x1="0" x2="0" y1="1" y2="0">
            <stop offset="0%" stop-color="#631f26" />
            <stop offset="28%" stop-color="#aa1923" />
            <stop offset="73%" stop-color="#b02038" />
            <stop offset="100%" stop-color="#c91c3d" />
          </linearGradient>
          <filter id="blur">
            <feGaussianBlur stdDeviation="10" result="coloredBlur" />
            <feGaussianBlur stdDeviation="0" result="blur" />
            <feBlend in="SourceGraphic" in2="coloredBlur" mode="normal"/>
          </filter>
        </defs>
        <g>
          <g class="s-logo">
            <path
              d="M121.893,107.085c0.191,5.409-0.747,10.9-2.904,16.471c-2.16,5.569-5.264,10.722-9.377,15.414
			c-4.113,4.694-9.009,8.708-14.706,12.01c-5.695,3.303-11.897,5.39-18.542,6.258c-5.028,0.682-9.908,0.88-14.606,0.613
			c-4.701-0.271-9.103-1.317-13.161-3.102c-1.15-0.67-2.064-0.34-2.746,0.991c-1.519,3.021-2.852,5.994-3.991,8.917
			c-1.141,2.924-1.576,5.827-1.277,8.733c-0.007,2.005,0.458,3.86,1.402,5.568c0.861,1.574,3.512,2.588,5.36,2.714
			c1.846,0.126,4.937-0.634,7.079-1.813c1.722-0.947,1.676-1.124,1.843-1.652c0.359-1.144-1.1-0.751-1.533-1.383
			c-0.227-0.33,0.229-0.907,1.201-2.374c3.827-5.786,3.797-9.775,3.801-9.772c-0.029-0.025,3.488,10.02,2.394,13.908
			c-0.346,1.165-1.451-1.582-5.317,1.516c-1.186,0.96-7.376,3.679-10.823,2.865c-4.096-0.963-6.705-4.897-6.98-8.17
			c-0.282-3.282,0.266-6.864,1.626-10.763c0.425-1.346,0.936-2.65,1.535-3.921c0.595-1.271,1.299-2.567,2.113-3.888
			c0.84-1.075,1.181-1.891,1.027-2.454c-0.152-0.562-0.61-1.169-1.37-1.814c-7.477-5.863-10.205-13.701-8.346-23.62
			c0.881-4.059,2.601-7.818,5.111-11.302c2.508-3.479,5.453-6.448,8.817-8.911c3.362-2.461,6.976-4.297,10.85-5.508
			c3.875-1.21,7.732-1.552,11.627-1.026c0.067,0.122,0.392,0.185,0.979,0.192c0.233,0.002,0.385,0.066,0.454,0.187
			c4.338-5.371,8.591-10.746,12.752-16.12c-0.158-0.48-0.508-0.903-1.05-1.267c-3.095-2.297-5.878-4.768-8.355-7.407
			c-2.479-2.638-4.657-5.569-6.536-8.79c-1.514-2.505-2.203-5.331-2.104-8.483c0.096-3.147,0.792-6.356,2.051-9.625
			c1.257-3.268,3.054-6.533,5.361-9.805c2.304-3.269,4.988-6.27,8.048-9.007c4.504-4.164,9.04-7.345,13.686-9.549
			c4.643-2.202,9.384-3.839,14.256-4.91c8.048-1.904,14.851-0.832,20.66,3.214c0.882-1.369,1.757-2.738,2.625-4.107
			c0.442,0.238,0.884,0.477,1.327,0.715c-0.883,1.429-1.773,2.857-2.67,4.286c0.514,0.357,1.02,0.746,1.519,1.162
			c0.498,0.418,0.99,0.863,1.474,1.338c2.527,2.741,3.781,6.161,3.706,10.271c-0.077,4.109-1.43,8.312-4.138,12.605
			c-3.229,5.009-7.368,7.875-12.322,8.585c-4.744,0.711-7.699-1.024-8.797-5.201c-0.107,0-0.15-0.029-0.13-0.09
			c0.019-0.058-0.026-0.089-0.132-0.089c-5.362,7.688-10.934,15.383-16.708,23.086c0.109,0,0.2,0.062,0.269,0.181
			c0.066,0.12,0.28,0.301,0.64,0.541c1.476,0.844,2.905,1.691,4.284,2.536c1.378,0.846,2.722,1.811,4.03,2.9
			c4.266,3.266,7.701,7.145,10.309,11.645C120.088,95.082,121.571,100.573,121.893,107.085z M48.547,146.979
			c0.527,0.269,0.981,0.408,1.359,0.422c0.378,0.015,0.867-0.397,1.474-1.236c2.792-3.338,5.77-6.496,8.926-9.47
			c3.153-2.97,6.582-5.699,10.274-8.185c1.311-0.823,1.837-1.854,1.585-3.084c-0.829-3.808-2.621-7.07-5.364-9.785
			c-0.57-0.374-1.138-0.747-1.705-1.12c-5.579,6.798-11.28,13.583-17.08,20.34c-0.515-0.259-1.028-0.518-1.542-0.776
			c5.79-6.687,11.482-13.404,17.053-20.135c-0.121-0.002-0.217-0.063-0.284-0.186c-0.12-0.002-0.228-0.032-0.32-0.097
			c-0.096-0.061-0.203-0.093-0.32-0.095c-1.829-0.514-3.749-0.577-5.777-0.192c-2.032,0.387-3.968,1.102-5.816,2.149
			c-1.852,1.048-3.498,2.366-4.941,3.958c-1.443,1.591-2.532,3.46-3.251,5.61C39.388,134.881,41.223,142.146,48.547,146.979z
			M85.291,58.952c0.913,2.743,2.358,4.983,4.344,6.716c1.985,1.737,3.978,3.504,5.98,5.305c0.133,0.242,0.379,0.481,0.736,0.721
			c0.177,0.121,0.322,0.18,0.433,0.181c10.559-14.147,20.435-28.267,29.58-42.372c-0.139-0.236-0.241-0.595-0.307-1.071
			c-0.07-0.474-0.172-0.831-0.307-1.071c-1.264-2.737-3.571-4.465-6.923-5.178c-2.475-0.595-5.048-0.654-7.739-0.179
			c-2.694,0.476-5.334,1.339-7.935,2.589c-2.604,1.249-5.091,2.888-7.479,4.908c-2.389,2.025-4.477,4.226-6.262,6.603
			c-1.788,2.381-3.034,4.64-3.717,6.781c-1.185,3.211-1.742,6.096-1.636,8.655C84.158,54.098,84.568,56.569,85.291,58.952z
			M67.207,149.726c3.926-0.229,7.775-1.121,11.525-2.688c3.747-1.566,7.223-3.589,10.419-6.065c3.194-2.471,5.962-5.341,8.315-8.59
			c2.35-3.245,4.081-6.723,5.217-10.41c1.218-3.933,1.688-7.617,1.44-11.052c-0.248-3.432-0.957-6.675-2.106-9.728
			c-0.646-1.83-1.493-3.384-2.542-4.666c-1.05-1.282-2.183-2.47-3.398-3.565c-1.217-1.098-2.503-2.13-3.86-3.106
			c-1.359-0.974-2.71-1.943-4.054-2.915c-0.184-0.121-0.443-0.184-0.785-0.187c-3.971,5.199-8.027,10.399-12.16,15.596
			c0.117,0.001,0.234,0.002,0.351,0.004c0.115,0.001,0.25,0.034,0.401,0.096c0.152,0.063,0.342,0.094,0.579,0.097
			c1.748,0.625,3.379,1.341,4.897,2.145c1.514,0.808,2.842,1.885,3.993,3.234c1.979,1.97,3.958,3.704,5.922,5.2
			c1.963,1.496,4.276,2.576,6.929,3.231c0.476,0.007,0.609,0.317,0.396,0.927c-0.011,0.369-0.165,0.552-0.462,0.547
			c-0.3-0.005-0.57-0.009-0.809-0.013c-0.6-0.01-1.25,0.042-1.95,0.151c-0.703,0.111-1.398,0.286-2.084,0.518
			c-3.47,0.925-6.884,2.152-10.254,3.678c-3.375,1.529-6.808,3.48-10.321,5.85c-4.021,2.728-7.697,5.633-11.017,8.727
			c-3.323,3.096-6.533,6.37-9.62,9.824c-1.006,1.194-0.907,1.876,0.307,2.046c1.414,0.302,2.821,0.542,4.226,0.72
			c1.399,0.178,2.745,0.417,4.04,0.713c1.185-0.078,2.291-0.127,3.327-0.153S66.146,149.812,67.207,149.726z M116.81,46.475
			c1.387-0.357,2.723-1.072,4.006-2.145c1.283-1.073,2.39-2.292,3.331-3.663c0.938-1.369,1.635-2.828,2.092-4.376
			c0.458-1.546,0.564-2.976,0.326-4.287c-0.831,1.25-1.667,2.5-2.51,3.75C121.686,39.326,119.271,42.899,116.81,46.475z"
            />
            <path
              d="M225.519,149.112c0.197,0.4,0.296,0.836,0.301,1.299c0.001,0.467-0.139,0.832-0.423,1.094
			c-0.286,0.264-0.572,0.292-0.857,0.089c-0.289-0.204-0.609-0.374-0.967-0.512c-0.537-0.206-1.073-0.413-1.609-0.619
			c-1.947-0.425-3.781-0.48-5.514-0.174c-1.734,0.31-3.495,1.443-5.299,3.414c-1.495,1.576-3.195,2.783-5.098,3.622
			c-1.906,0.835-3.811,1.24-5.708,1.204c-1.898-0.035-3.707-0.441-5.409-1.205c-1.707-0.768-3.05-1.957-4.019-3.566
			c-1.482-2.279-3.553-3.434-6.242-3.484c-2.692-0.048-5.176,0.955-7.485,3.016c-0.359,0.521-0.713,0.713-1.063,0.572
			c-0.174-0.4-0.093-0.794,0.24-1.183c0.846-1.693,1.774-3.319,2.774-4.872c0.999-1.554,2.31-2.966,3.93-4.243
			c0.448-0.384,0.723-0.833,0.825-1.354c0.444-2.27,0.887-4.53,1.328-6.782c0.504-2.57,1.272-6.484,2.293-11.689
			c1.021-5.203,2.125-10.994,3.308-17.349c1.179-6.355,2.429-12.928,3.731-19.724c1.302-6.794,2.488-13.076,3.566-18.878
			c-0.065-0.242-0.426-0.363-1.083-0.364c-1.207-0.002-2.325-0.032-3.353-0.095c-1.03-0.063-2.17,0.028-3.423,0.266
			c-0.707,0.241-0.976-0.062-0.806-0.906c0.29-1.449,0.499-2.501,0.631-3.166c0.131-0.661,0.329-1.112,0.595-1.355
			c0.264-0.24,0.662-0.329,1.194-0.269c0.53,0.063,1.393,0.091,2.589,0.093c0.675-0.118,1.31-0.18,1.908-0.179
			c0.596,0,1.221,0.001,1.873,0.002c0.542,0,0.89-0.116,1.043-0.36c0.871-4.756,1.725-9.495,2.559-14.222
			c0.274-1.555,0.514-3.078,0.723-4.574c0.207-1.494,0.16-2.895-0.135-4.209c-0.554-3.461-2.1-5.606-4.64-6.441
			c-0.082-0.119-0.164-0.238-0.246-0.357c0.85-0.715,1.635-1.073,2.358-1.073c0.721,0,1.496,0,2.322,0
			c2.993,0,5.896-0.385,8.699-1.163c2.799-0.773,5.522-2.114,8.149-4.019c0.221-0.117,0.442-0.237,0.666-0.358
			c0.221-0.118,0.416-0.058,0.589,0.179c-0.273,0.478-0.623,0.954-1.047,1.43c-0.429,0.477-0.78,0.954-1.053,1.431
			c-2.713,4.53-4.428,9.535-5.08,15.033c-0.731,5.979-1.674,11.851-2.842,17.633c0.006,0.603,0.392,0.904,1.153,0.905
			c1.52,0.001,3.007,0.033,4.462,0.095c1.456,0.063,2.952,0.033,4.49-0.087c1.32-0.118,2.588-0.088,3.813,0.094
			c1.221,0.182,2.485,0.272,3.788,0.274c0.216,0,0.406,0,0.57,0c0.163,0,0.335,0.122,0.521,0.363
			c0.184,0.243,0.185,0.452-0.003,0.634c-0.188,0.179-0.353,0.393-0.494,0.633c-1.317,1.692-2.569,3.413-3.748,5.167
			c-1.183,1.757-2.248,3.607-3.199,5.55c-0.788,1.58-1.524,3.165-2.208,4.749c-0.687,1.585-1.162,3.235-1.423,4.949
			c-0.28,1.837-0.207,3.56,0.232,5.16c0.379,0.494,0.372,0.925-0.026,1.294c-0.134,0.123-0.522,0.061-1.166-0.189
			c-1.916-0.867-3.033-2.411-3.357-4.62c-0.773-2.945-0.783-5.787-0.065-8.526c0.715-2.739,2.004-5.437,3.841-8.101
			c0.441-0.727,0.593-1.181,0.457-1.362c-0.137-0.183-0.49-0.211-1.055-0.092c-1.097-0.002-2.137-0.003-3.124-0.004
			c-0.986-0.002-1.962-0.064-2.929-0.187c-0.986-0.001-1.585,0.302-1.795,0.906c-2.838,16.938-5.865,34.145-9.002,51.891
			c-1.11,6.275-2.233,12.617-3.367,19.039c-0.69,3.892,0.79,6.411,4.474,7.518c2.296,0.431,4.348-0.263,6.125-2.081
			c0.889-0.905,1.562-1.849,2.023-2.825c0.461-0.977,0.462-1.988,0.013-3.037c-0.392-1.438-0.565-2.837-0.534-4.197
			c0.033-1.36,0.284-2.745,0.752-4.16c0.488-1.542,1.133-2.947,1.938-4.223c0.803-1.273,1.974-2.221,3.51-2.85
			c1.416-0.626,2.716-0.713,3.909-0.257c1.192,0.459,2.267,1.141,3.224,2.043c0.993,0.651,0.861,1.164-0.396,1.538
			c-1.4,0.504-2.547,1.136-3.445,1.902c-0.9,0.764-1.463,1.919-1.691,3.468c-0.248,1.68-0.156,3.108,0.288,4.284
			c0.298,1.306,0.815,2.42,1.545,3.343c0.73,0.924,1.534,1.784,2.416,2.582c0.984,0.931,1.9,1.929,2.753,2.994
			C224.437,146.387,225.081,147.649,225.519,149.112z"
            />
            <path
              d="M264.426,58.61c-2.875,3.738-4.567,8.209-5.012,13.439c-1.714,20.195-3.56,40.861-5.479,62.512
			c-0.244,2.739,0.666,4.915,2.749,6.509c2.318,1.73,4.854,2.18,7.568,1.334c2.712-0.841,4.713-2.715,5.995-5.596
			c0.564-1.177,0.91-2.353,1.046-3.527c0.131-1.175,0.24-2.41,0.321-3.709c0.359-7.787,0.749-15.445,1.16-23.003
			c0.411-7.56,0.901-15.016,1.462-22.397c0.283-2.827,0.075-5.432-0.61-7.814c-0.687-2.382-1.734-4.602-3.134-6.671
			c-0.094-0.243-0.243-0.485-0.445-0.729c-0.288-0.607-0.315-1.03-0.079-1.274c0.234-0.242,0.401-0.273,0.5-0.092
			c0.096,0.183,0.25,0.336,0.462,0.456c1.664,1.337,3.529,1.885,5.577,1.642c2.046-0.241,3.777-1.274,5.178-3.093
			c2.437-2.909,5.455-5.02,9.07-6.35c0.439-0.24,0.711-0.239,0.813,0c0.208,0.243,0.256,0.395,0.147,0.454
			c-0.111,0.063-0.225,0.212-0.34,0.452c-1.81,2.297-3.129,4.78-3.947,7.449c-0.819,2.674-1.352,5.471-1.583,8.406
			c-0.072,1.714-0.117,3.396-0.129,5.055c-0.015,1.658-0.057,3.351-0.13,5.077c-0.474,8.515-0.843,17.016-1.09,25.523
			c-0.25,8.511-0.684,17.282-1.321,26.365c-0.029,0.66,0.189,1.255,0.659,1.783c0.832,0.666,1.477,1.46,1.936,2.388
			c0.456,0.931,0.853,1.928,1.186,2.994c0.226,0.534,0.361,1.069,0.404,1.6c0.043,0.535,0.116,1.071,0.222,1.605
			c-0.02,0.537-0.159,0.937-0.417,1.205c-0.263,0.268-0.448,0.267-0.563-0.002c-0.115-0.269-0.233-0.471-0.352-0.605
			c-0.484-0.403-0.936-0.771-1.358-1.109c-0.423-0.333-0.938-0.636-1.548-0.907c-0.734-0.269-1.382-0.372-1.943-0.308
			c-0.563,0.064-1.173,0.495-1.833,1.293c-5.402,6.268-12.035,8.748-19.613,7.331c-2.846-0.698-5.333-1.797-7.458-3.293
			c-2.127-1.493-4.015-3.38-5.658-5.657c-0.558-0.667-1.233-0.739-2.03-0.218c-2.561,1.97-5.239,3.343-8.013,4.114
			c-2.779,0.771-5.608,1.071-8.469,0.901c-0.502-0.006-0.727-0.209-0.677-0.608c0.017-0.132,0.229-0.329,0.637-0.591
			c1.992-0.907,3.637-2.119,4.934-3.631c1.298-1.509,2.543-3.181,3.741-5.017c0.566-0.651,0.917-1.569,1.049-2.75
			c0.75-6.682,1.492-13.27,2.224-19.777c0.761-6.764,1.527-13.345,2.302-19.771c0.771-6.421,1.504-12.809,2.197-19.176
			c0.479-4.408-0.697-7.999-3.453-10.794c-0.702-0.606-1.007-1.275-0.923-2.002c2.437,0.731,5.179,0.794,8.202,0.189
			c1.913-0.48,3.709-1.207,5.384-2.176c1.673-0.967,3.33-2.116,4.965-3.445c0.83-0.843,1.732-1.568,2.702-2.172
			C262.575,59.815,263.514,59.213,264.426,58.61z"
            />
            <path
              d="M319.406,155.688c-1.006,0.137-1.979,0.175-2.923,0.105c-0.941-0.068-1.918-0.304-2.923-0.708
			c-1.258-0.675-2.481-1.349-3.667-2.025c-1.189-0.672-2.278-1.546-3.267-2.622c-1.735-1.742-3.645-2.979-5.739-3.718
			c-2.099-0.733-4.317-1.169-6.664-1.309c-0.249,0-0.529,0.032-0.837,0.098c-0.314,0.067-0.528-0.032-0.642-0.302
			c-0.243-0.265-0.205-0.464,0.109-0.598c0.312-0.13,0.595-0.329,0.852-0.595c3.436-3.707,5.164-8.189,5.266-13.397
			c0.496-19.136,0.848-37.492,1.079-55.439c0.042-2.199-0.505-4.054-1.625-5.574c-1.121-1.519-2.428-2.884-3.921-4.098
			c-0.213-0.24-0.455-0.391-0.724-0.454c-0.271-0.061-0.348-0.272-0.227-0.636c0.116-0.242,0.31-0.363,0.583-0.363
			c0.271,0.001,0.517-0.06,0.739-0.183c1.966-0.24,3.753,0,5.373,0.728c1.622,0.727,3.186,1.758,4.705,3.093
			c0.757,0.729,1.473,0.669,2.136-0.183c1.219-1.82,2.723-3.241,4.516-4.272c1.795-1.026,3.662-1.659,5.611-1.903
			c3.136-0.604,6.107-0.512,8.946,0.271c2.837,0.787,5.538,2.328,8.137,4.628c1.918,1.699,3.917,2.124,5.957,1.272
			c0.854-0.483,1.146-0.119,0.875,1.093c-0.37,1.458-1.001,2.675-1.897,3.648c-0.892,0.976-1.936,1.77-3.131,2.379
			c-1.083,0.613-3.122,0.905-4.719,0.587c-3.294-0.655-5.007-2.193-5.799-2.96c-0.722-0.699-1.3-1.46-2.185-1.824
			c-0.89-0.365-1.823-0.425-2.808-0.184c-2.193,0.61-4.049,1.979-5.585,4.112c-1.542,2.138-2.317,4.491-2.313,7.069
			c0.014,9.209-0.002,18.443-0.047,27.743c-0.047,9.301-0.006,18.856,0.135,28.724c0.007,5.002,1.359,9.859,4.148,14.563
			c0.254,0.675,0.633,1.212,1.142,1.615C318.574,154.474,319.021,155.013,319.406,155.688z"
            />
            <path
              d="M377.282,159.202c-1.08-0.667-2.185-1.331-3.316-1.994c-1.134-0.664-2.181-1.46-3.14-2.393
			c-1.89-1.462-3.779-2.719-5.673-3.776c-1.897-1.056-3.918-1.841-6.071-2.361c-3.415-0.778-6.51-0.694-9.322,0.256
			c-0.037-0.667,0.061-1.132,0.293-1.403c0.233-0.268,0.526-0.602,0.875-1.003c3.292-3.078,4.75-6.922,4.443-11.5
			c-0.453-6.797-0.932-13.496-1.43-20.112c-0.5-6.615-0.961-13.15-1.386-19.619c-0.416-6.342-0.824-12.62-1.224-18.849
			c-0.07-1.1-0.348-1.953-0.829-2.564c-0.992-1.705-2.021-3.255-3.091-4.652c-1.067-1.396-2.44-2.516-4.123-3.365
			c-0.332-0.119-0.511-0.364-0.528-0.728c-0.013-0.241,0.2-0.362,0.636-0.362c2.5-0.12,4.938,0.18,7.332,0.905
			c2.394,0.726,4.688,1.938,6.901,3.635c0.474,0.487,0.819,0.7,1.036,0.637c0.216-0.06,0.476-0.274,0.777-0.638
			c1.487-2.064,3.15-3.76,4.999-5.095c1.847-1.33,3.982-2.298,6.42-2.904c6.492-1.207,12.207,1.013,17.415,6.7
			c0.619,0.608,3.183,3.735,3.981,9.112c1.217,8.297,2.485,16.634,3.509,25.06c1.021,8.424,2.191,17.054,3.523,25.932
			c0.245,1.803,0.459,3.577,0.633,5.326c0.178,1.75,0.393,3.538,0.64,5.364c0.544,3.13,1.411,6.115,2.612,8.944
			c1.2,2.833,2.986,5.57,5.385,8.207c0.436,0.395,0.547,0.728,0.333,1c-0.088,0.267-0.206,0.336-0.351,0.206
			c-0.145-0.132-0.344-0.195-0.597-0.19c-2.132-0.771-4.121-1.713-5.97-2.824c-1.849-1.11-3.57-2.521-5.159-4.231
			c-1.735-1.837-3.764-2.905-6.101-3.214c-2.339-0.303-4.412,0.278-6.242,1.758c-0.222,0.27-0.387,0.471-0.495,0.604
			c-0.113,0.135-0.305,0.072-0.583-0.194c-0.141-0.128-0.121-0.528,0.059-1.196c0.094-0.265,0.253-0.531,0.474-0.801
			c1.494-2.268,2.552-4.688,3.18-7.25c0.629-2.562,0.696-5.334,0.229-8.311c-1.028-7.77-1.949-15.417-2.768-22.969
			c-0.818-7.55-1.602-15.066-2.345-22.565c-0.126-1.107-0.287-2.239-0.473-3.407c-0.19-1.163-0.563-2.232-1.118-3.212
			c-1.307-2.687-3.184-4.418-5.644-5.21c-2.462-0.787-4.714-0.452-6.796,1.014c-1.86,1.466-2.69,3.481-2.466,6.054
			c1.785,20.405,3.696,41.36,5.673,63.382c0.238,2.648,0.809,5.205,1.721,7.673C374.035,154.546,375.417,156.919,377.282,159.202z"
            />
            <path
              d="M405.664,74.186c-3.68-1.572-5.583-4.122-5.757-7.633c1.703,1.209,3.216,1.753,4.513,1.63
			c1.389-0.244,2.356-0.73,2.9-1.455c1.446-1.936,3.203-3.384,5.273-4.354c2.067-0.967,4.26-1.452,6.592-1.454
			c2.331-0.001,4.714,0.448,7.164,1.348c2.45,0.902,4.822,2.194,7.128,3.88c0.617,0.361,1.137,0.753,1.551,1.176
			c0.414,0.422,0.889,0.875,1.429,1.355c1.205,1.084,3.69,4.419,4.29,7.074c2.226,9.946,4.454,19.952,6.678,30.067
			c2.226,10.12,4.511,20.407,6.835,30.916c0.482,2.18,1.845,4.448,4.118,6.803c2.273,2.357,4.556,3.84,6.832,4.439
			c0.279,0.125,0.575,0.183,0.888,0.176c0.31-0.006,0.514,0.183,0.605,0.572c0.123,0.52-0.099,0.915-0.665,1.188
			c-1.638,1.603-4.087,2.25-7.31,1.929c-2.067-0.217-4.041-0.602-5.921-1.153c-1.881-0.551-3.718-1.529-5.508-2.937
			c-0.455-0.384-0.879-0.308-1.271,0.22c-1.251,1.859-2.787,3.302-4.603,4.327c-1.817,1.025-3.902,1.696-6.241,2.006
			c-0.607,0.146-1.274,0.291-2.006,0.437c-0.731,0.147-1.401,0.291-2.008,0.436c-2.854,0.319-5.522-0.128-7.994-1.35
			c-2.47-1.22-4.759-2.941-6.854-5.159c-2.101-2.214-3.81-4.661-5.14-7.328c-1.329-2.665-2.332-5.485-3.019-8.458
			c-0.924-3.743-1.529-7.433-1.831-11.067s-0.062-7.215,0.699-10.747c1.376-6.43,4.518-11.601,9.348-15.583
			c2.021-1.614,3.997-3.226,5.929-4.835c1.933-1.604,3.886-3.146,5.867-4.623c1.396-0.984,1.898-2.454,1.521-4.406
			c-0.425-2.194-0.845-4.384-1.263-6.568c-0.14-0.73-0.409-1.274-0.812-1.638c-1.07-0.967-2.121-1.722-3.158-2.267
			c-1.038-0.543-2.202-0.751-3.504-0.63c-1.187,0.122-5.141,2.851-6.662,3.488C412.377,74.813,407.915,75.156,405.664,74.186z
			M431.563,95.602c-1.786,0.874-3.355,1.995-4.714,3.364c-8.429,9.219-10.483,21.147-5.147,36.096
			c0.553,1.808,1.334,3.52,2.353,5.132c1.02,1.617,2.312,3.002,3.886,4.157c0.856,0.643,1.769,1.219,2.732,1.726
			c0.965,0.51,1.923,0.627,2.869,0.346c2.417-0.433,4.17-1.543,5.253-3.321c1.08-1.778,1.361-3.954,0.852-6.531
			c-2.484-12.555-4.923-24.824-7.286-36.893C432.093,98.317,431.827,96.958,431.563,95.602L431.563,95.602z"
            />
            <path
              d="M145.823,74.018c-3.012-1.575-3.832-4.108-2.53-7.593c1.199,1.206,2.48,1.75,3.83,1.633
			c1.493-0.237,2.667-0.717,3.515-1.436c2.263-1.918,4.626-3.355,7.101-4.315c2.472-0.957,4.865-1.435,7.198-1.432
			c2.333,0.004,4.53,0.458,6.605,1.362c2.076,0.906,3.908,2.202,5.506,3.891c0.466,0.362,0.819,0.756,1.055,1.179
			c0.237,0.423,0.52,0.876,0.856,1.359c0.75,1.086,1.821,4.427,1.281,7.082c-2.03,9.943-4.195,19.946-6.457,30.056
			c-2.264,10.114-4.587,20.395-6.951,30.894c-0.49,2.179-0.146,4.472,1.064,6.894c1.208,2.425,2.812,3.992,4.8,4.684
			c0.221,0.136,0.489,0.207,0.801,0.212c0.31,0.006,0.428,0.204,0.346,0.596c-0.109,0.523-0.505,0.908-1.185,1.157
			c-2.332,1.527-5.039,2.06-8.089,1.599c-1.952-0.306-3.739-0.771-5.358-1.396c-1.621-0.624-3.012-1.664-4.168-3.12
			c-0.282-0.396-0.734-0.338-1.357,0.165c-2.061,1.774-4.22,3.121-6.469,4.042c-2.253,0.92-4.616,1.479-7.075,1.674
			c-0.665,0.113-1.391,0.224-2.181,0.332c-0.792,0.109-1.519,0.217-2.183,0.329c-2.973,0.177-5.431-0.378-7.357-1.662
			c-1.926-1.283-3.456-3.03-4.577-5.241c-1.125-2.207-1.759-4.615-1.918-7.222c-0.159-2.603,0.078-5.345,0.702-8.229
			c0.725-3.632,1.747-7.211,3.051-10.741c1.304-3.53,3.125-7.004,5.446-10.438c4.216-6.25,9.631-11.29,16.209-15.167
			c2.728-1.571,5.409-3.146,8.042-4.722c2.631-1.571,5.256-3.086,7.879-4.537c1.82-0.967,2.961-2.424,3.426-4.37
			c0.523-2.187,1.042-4.369,1.557-6.546c0.172-0.727,0.134-1.272-0.112-1.635c-0.659-0.968-1.391-1.726-2.196-2.271
			c-0.808-0.544-1.885-0.756-3.237-0.641c-1.237,0.118-6.351,2.815-8.146,3.439C152.277,74.673,147.664,74.99,145.823,74.018z
			M162.458,95.441c-2.166,0.848-4.227,1.939-6.188,3.279c-12.472,9.029-19.816,20.639-21.09,35.302
			c-0.248,1.772-0.222,3.457,0.083,5.055c0.303,1.602,0.98,2.992,2.038,4.17c0.572,0.656,1.226,1.25,1.963,1.779
			c0.732,0.533,1.634,0.685,2.699,0.448c2.593-0.326,4.824-1.342,6.684-3.041c1.86-1.699,3.101-3.825,3.735-6.373
			c3.089-12.408,6.131-24.573,9.082-36.567C161.796,98.141,162.128,96.79,162.458,95.441
			C162.458,95.441,162.458,95.441,162.458,95.441z"
            />
            <path
              d="M443.811,28.613c0.204,0,0.524,0.299,0.957,0.893c1.917,1.789,3.998,2.681,6.225,2.681c2.226,0,3.838-1.011,4.824-3.038
			c0.735-1.31,1.716-2.323,2.937-3.037c1.223-0.714,2.57-1.072,4.055-1.072c1.483-0.001,2.986,0.298,4.512,0.893
			c1.528,0.597,3.017,1.549,4.476,2.858c2.655,2.383,3.43,5.263,3.889,8.419c0.459,3.159-1.108,5.803-3.074,7.595
			c-0.183,0.121-0.172,0.147-0.336,0.327c-0.169,0.181-0.386,0.152-0.657-0.09c-0.274-0.236-0.388-0.475-0.344-0.715
			c0.044-0.238,0.087-0.478,0.133-0.717c-0.104-2.865,0.16-5.228-2.105-6.777c-2.4-1.669-4.446-1.788-6.187-0.356
			c-1.608,1.551-2.1,3.697-1.439,6.441c0.622,2.149,1.201,4.297,1.73,6.45c0.529,2.151,1.063,4.306,1.604,6.462
			c1.411,5.631,2.805,11.186,4.179,16.674c1.371,5.49,2.824,11.09,4.354,16.811c1.824,6.821,3.702,13.651,5.635,20.508
			c1.93,6.857,3.887,13.862,5.855,21.035c0.484,1.763,0.913,3.563,1.293,5.396c0.379,1.836,0.937,3.644,1.673,5.418
			c0.627,1.394,1.215,2.728,1.751,3.998c0.537,1.271,1.23,2.544,2.087,3.81c1.527,2.799,3.51,5.143,5.952,7.013
			c-0.436,0.272-0.799,0.348-1.085,0.229c-0.295-0.123-0.601-0.309-0.922-0.558c-6.831-3.428-13.689-5.068-20.718-4.897
			c-1.008,0.024-2.01,0.049-3.014,0.073c-1.007,0.024-2.059-0.407-3.15-1.295c3.104-1.243,4.899-3.135,5.392-5.663
			c0.494-2.527,0.327-5.38-0.479-8.558c-4.003-16.269-7.929-31.999-11.677-47.365c-3.753-15.367-7.449-30.611-10.967-45.895
			c-0.053-0.239-0.106-0.478-0.159-0.716c-0.107-0.954-0.324-1.818-0.654-2.595c-0.326-0.775-0.938-1.521-1.82-2.237
			c-1.043-0.951-1.896-2.026-2.563-3.218c-0.666-1.19-1.341-2.442-2.023-3.753C443.645,29.567,443.6,29.092,443.811,28.613z"
            />
            <path
              d="M503.412,48.087c0.659,1.429,0.951,2.771,0.866,4.024c-0.085,1.254-0.434,2.359-1.046,3.314
			c-0.614,0.958-1.467,1.677-2.559,2.156c-1.095,0.479-2.275,0.603-3.534,0.364c-2.309-0.474-4.668-1.847-7.049-4.117
			c-1.207-1.191-1.24-1.22-2.37-2.355c-1.133-1.133-2.933-4.421-3.961-3.944c-1.037,0.478,0.209,3.298,0.357,5.33
			c-0.039,0.241,0.176,0.362,0.299,0.599c0.121,0.242,0.021,0.359-0.302,0.359c-0.181,0.12-0.36,0.063-0.533-0.178
			c-0.176-0.237-0.526-0.359-0.699-0.599c-2.734-2.867-5.794-6.075-6.014-9.295c-0.108-2.385,0.183-4.031,2.005-5.047
			c1.821-1.013,2.881-0.676,5.127,0.633c1.622,0.955,3.021,3.103,4.417,4.292c1.844,1.67,3.712,3.07,5.588,4.201
			c1.876,1.133,3.922,1.818,6.129,2.054c0.852,0,1.516-0.181,1.99-0.539C502.6,48.981,503.028,48.564,503.412,48.087z
			M508.184,66.002c-2.604,1.085-4.034,2.796-4.278,5.138c-0.245,2.343,0.087,4.956,1.021,7.843
			c3.232,10.35,6.592,20.725,10.038,31.167c3.443,10.441,6.995,21.006,10.607,31.727c0.673,1.997,1.695,3.733,3.083,5.207
			c1.381,1.475,2.961,2.753,4.735,3.832c0.338,0.243,0.615,0.391,0.826,0.446c0.212,0.058,0.293,0.213,0.254,0.466
			c0.087,0.253-0.144,0.51-0.688,0.785c-0.758,0.407-1.537,0.56-2.337,0.463c-0.804-0.1-1.689-0.261-2.657-0.481
			c-1.558-0.453-3.017-1.012-4.378-1.667c-1.359-0.656-2.774-1.278-4.232-1.872c-3.918-1.532-7.157-1.635-9.78-0.283
			c-0.188,0.006-0.376,0.011-0.564,0.017c-0.154-0.504-0.095-0.826,0.181-0.963c0.271-0.135,0.496-0.333,0.67-0.594
			c2.09-2.226,2.562-5.094,1.452-8.6c-1.896-6.39-3.856-12.663-5.866-18.834c-2.012-6.17-3.993-12.364-5.936-18.583
			c-2.647-8.477-5.242-16.888-7.764-25.257c-0.907-3.009-2.094-5.863-3.546-8.563c-1.453-2.699-3.447-5.24-5.966-7.636
			c-0.034-0.12-0.068-0.24-0.103-0.36c0.15-0.237,0.617-0.118,1.401,0.358c6.113,3.586,12.336,5.254,18.494,5
			c0.872-0.002,1.72-0.004,2.54-0.006C506.216,64.75,507.143,65.168,508.184,66.002z"
            />
            <path
              d="M522.79,89.555c0.815-0.608,1.498-1.337,2.047-2.185c0.554-0.844,0.891-1.87,1.025-3.076
			c0.299-1.686,0.142-3.365-0.464-5.045c-0.95-2.632-2.753-3.89-5.1-5.376c-1.611-1.021-4.204-2.977-6.048-5.666
			c-1.776-2.586-2.393-5.333-2.095-5.371c0.317-0.041,2.226,2.537,3.897,3.729c1.413,1.009,2.521,1.187,2.753,1.164
			c1.346-0.243,1.323-0.502,1.731-1.221c1.086-1.916,2.573-3.351,4.467-4.313c1.893-0.958,3.999-1.441,6.337-1.446
			c2.337-0.005,4.81,0.438,7.438,1.326c2.63,0.889,5.256,2.164,7.893,3.823c0.688,0.355,1.285,0.74,1.782,1.157
			c0.498,0.416,1.062,0.862,1.693,1.334c1.427,1.068,2.605,1.242,3.526,0.524c0.233-0.239,0.745-0.644,0.91-0.812
			c0.464-0.459,0.753-1.636,0.815-2.268c0.062-0.625-0.149-2.247,0.12-2.129c0.268,0.118,0.85,1.171,1.096,1.764
			c0.673,1.901,2.021,5.775-0.765,7.901c-0.478,0.364-0.366,1.136-0.019,1.967c4.173,9.752,8.423,19.477,12.67,29.182
			c4.247,9.709,8.578,19.456,12.928,29.25c0.902,2.032,2.706,4.125,5.443,6.271c2.736,2.146,5.318,3.47,7.729,3.977
			c0.301,0.11,0.612,0.157,0.929,0.145c0.312-0.012,0.554,0.16,0.718,0.515c0.22,0.476,0.069,0.845-0.452,1.107
			c-1.361,1.508-3.714,2.155-7.026,1.928c-2.127-0.157-4.189-0.469-6.188-0.94c-1.998-0.472-4.034-1.339-6.103-2.611
			c-0.529-0.348-0.941-0.269-1.239,0.229c-0.915,1.751-2.199,3.123-3.842,4.111c-1.644,0.989-3.622,1.657-5.923,1.995
			c-0.583,0.147-1.229,0.298-1.942,0.449c-0.707,0.154-1.358,0.3-1.939,0.449c-2.816,0.358-5.588,0.002-8.304-1.087
			c-2.714-1.087-5.339-2.651-7.867-4.697c-2.527-2.04-4.712-4.32-6.555-6.823c-1.846-2.5-3.394-5.166-4.655-7.988
			c-1.649-3.554-2.971-7.078-3.976-10.565c-1.01-3.487-1.466-6.939-1.39-10.358c0.131-6.225,2.276-11.271,6.355-15.171
			c1.717-1.581,3.388-3.16,5.018-4.737c1.627-1.573,3.289-3.084,4.994-4.534c1.208-0.965,1.425-2.403,0.663-4.313
			c-0.856-2.148-1.706-4.295-2.549-6.441c-0.281-0.716-0.658-1.252-1.131-1.608c-1.264-0.949-2.466-1.691-3.609-2.226
			c-1.148-0.533-2.357-0.736-3.64-0.614c-1.171,0.124-1.844,0.694-2.018,1.709c-0.175,1.019,0.29,2.124,1.41,3.314
			c2.438,2.622,3.671,4.86,3.65,6.718c-0.024,1.86-0.755,3.572-2.213,5.143c-2.893,2.903-6.832,3.965-11.706,3.153
			c-0.272-0.116-0.476-0.206-0.612-0.267C523.328,89.945,523.105,89.796,522.79,89.555z M550.505,94.744
			c-1.625,0.857-2.984,1.952-4.085,3.288c-6.669,8.975-6.387,20.552,1.851,34.648c0.902,1.705,2.018,3.312,3.35,4.814
			c1.333,1.508,2.897,2.79,4.701,3.847c0.985,0.587,2.013,1.112,3.08,1.569c1.066,0.459,2.054,0.551,2.957,0.271
			c2.354-0.45,3.916-1.519,4.671-3.201c0.757-1.681,0.629-3.725-0.378-6.141c-4.9-11.77-9.779-23.479-14.54-35.148
			C551.574,97.375,551.039,96.06,550.505,94.744z"
            />
          </g>
        </g>
      </svg>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="53.736 16.092 516.044 164.859"
        enable-background="new 53.736 16.092 516.044 164.859"
        xml:space="preserve"
        class="s-svg give-away"
      >
        <g class="s-logo give-away">
          <path
            d="M146.934,170.354c-0.576,0.19-1.079,0.166-1.511-0.072c-0.432-0.241-0.888-0.504-1.367-0.792
		c-3.166-2.496-6.499-4.629-10-6.403c-3.502-1.776-7.266-3.093-11.295-3.957c-1.824-0.384-3.791-0.576-5.899-0.576
		c0-0.48,0.145-0.719,0.433-0.719c0.287,0,0.526-0.05,0.719-0.145c2.493-0.575,4.651-1.919,6.474-4.028
		c1.822-2.111,2.734-4.414,2.734-6.906c-0.097-1.536-0.144-3.047-0.144-4.533c0-1.488,0-3,0-4.532
		c-4.222,1.151-8.682,1.151-13.381,0c-0.96-0.193-1.535-0.193-1.726,0v11.079c-0.481,1.63-1.104,3.116-1.871,4.46
		c-0.769,1.151-1.705,2.206-2.806,3.165c-1.104,0.958-2.614,1.439-4.532,1.439c-1.92,0.191-3.694-0.481-5.324-2.015
		c-1.248-1.344-1.896-2.926-1.942-4.748c-0.05-1.823,0.503-3.406,1.655-4.749c0.576-0.672,1.245-1.176,2.014-1.51
		c0.767-0.337,1.63-0.36,2.59-0.072c1.822,0.576,2.781,1.727,2.877,3.453c0.095,0.191,0.145,0.479,0.145,0.864
		c0,0.382-0.288,0.526-0.863,0.431c-1.151-0.193-1.776,0.239-1.871,1.296c-0.097,0.575-0.071,1.126,0.072,1.654
		c0.143,0.526,0.503,0.936,1.079,1.224c1.151,0.862,2.35,0.91,3.597,0.144c2.015-1.633,3.26-3.741,3.741-6.331v-10.503
		c-0.193-0.193-0.385-0.288-0.576-0.288c-3.359-0.96-6.402-2.396-9.136-4.316c-2.734-1.917-5.252-4.123-7.555-6.619
		c-4.028-4.507-7.17-9.4-9.424-14.675c-2.255-5.275-3.813-10.839-4.676-16.69c-2.206-14.771-0.097-28.633,6.331-41.582
		c4.604-9.303,11.367-16.594,20.287-21.87c4.124-2.397,8.321-3.814,12.589-4.245c4.267-0.433,8.609,0.265,13.021,2.086
		c3.166,1.344,5.756,3.143,7.77,5.395c2.014,2.255,3.116,5.205,3.309,8.85c0.191,2.495-0.071,4.845-0.792,7.05
		c-0.719,2.208-2.039,4.222-3.957,6.043c-1.632,1.536-3.502,2.399-5.611,2.591c-2.112,0.193-4.222-0.239-6.332-1.295
		c-2.782-1.343-4.988-3.548-6.617-6.619c-0.769-1.63-0.96-3.357-0.576-5.18c0.381-1.533,0.792-2.446,1.222-2.733
		c0.432-0.288,1.367-0.191,2.806,0.287c1.63,0.576,3.068,0.576,4.316,0c1.247-0.575,2.109-1.533,2.59-2.878
		c0.479-1.341,0.335-2.852-0.431-4.532c-0.769-1.676-1.92-2.756-3.453-3.237c-4.222-1.534-8.585-1.389-13.093,0.431
		c-0.194,0.097-0.384,0.241-0.576,0.432v33.956v3.454v11.798v7.626v6.762v2.302v4.173v8.921h14.675c0-1.247-0.024-2.493-0.072-3.741
		c-0.048-1.245-0.071-2.541-0.071-3.885h-14.532v-1.583h14.532c0-0.958-0.025-1.918-0.072-2.878
		c-0.05-0.957-0.072-1.917-0.072-2.877c0-1.917-0.455-3.619-1.367-5.107c-0.912-1.486-2.038-2.756-3.381-3.813
		c-0.48-0.288-1.127-0.67-1.941-1.151c-0.816-0.478-1.608-0.935-2.374-1.366c-0.769-0.432-1.417-0.839-1.942-1.223
		c-0.529-0.382-0.745-0.669-0.648-0.864c0.191-0.478,0.624-0.597,1.295-0.359c0.67,0.24,1.151,0.457,1.439,0.647
		c3.357,1.632,6.738,2.327,10.144,2.087c3.403-0.239,6.209-1.318,8.417-3.238c0.767-0.767,1.533-1.773,2.302-3.021
		c1.246-2.014,1.773-3.957,1.583-5.828c-0.193-1.871-0.672-3.14-1.438-3.812c-0.672-0.479-1.417-0.767-2.23-0.864
		c-0.816-0.095-1.511,0.05-2.086,0.431c-0.863,0.576-1.248,1.295-1.151,2.159c0,0.481,0.24,0.984,0.72,1.51
		c0.478,0.529,1.102,0.792,1.87,0.792c0.479,0,0.886,0.144,1.224,0.432c0.334,0.288,0.454,0.719,0.36,1.295
		c-0.194,1.438-1.079,2.614-2.662,3.525c-1.583,0.913-3.094,1.272-4.533,1.078c-1.439-0.094-2.662-0.55-3.669-1.366
		c-1.008-0.813-1.777-1.821-2.303-3.021c-0.527-1.198-0.815-2.469-0.862-3.813c-0.05-1.342,0.215-2.684,0.791-4.028
		c0.766-1.822,1.917-3.26,3.453-4.316c1.534-1.055,3.31-1.534,5.324-1.44c2.015,0.098,3.885,0.625,5.612,1.583
		c1.727,0.96,3.021,2.399,3.884,4.316c1.246,2.4,1.703,4.798,1.367,7.194c-0.337,2.399-1.319,4.702-2.95,6.907
		c-0.575,1.057-1.007,2.59-1.295,4.604c-0.288,2.015-0.385,3.741-0.288,5.181v63.595c0.576,2.591,1.486,5.059,2.734,7.41
		c1.246,2.35,2.972,4.484,5.18,6.403V170.354z M110.532,34.24c-0.193-0.094-0.576,0.05-1.151,0.433
		c-1.632,1.056-3.118,2.229-4.46,3.524c-1.344,1.295-2.59,2.758-3.74,4.389c-2.496,3.454-4.27,7.122-5.324,11.007
		c-1.057,3.885-1.824,7.938-2.303,12.158c-0.672,5.179-0.985,10.384-0.935,15.611c0.047,5.229,0.454,10.432,1.223,15.611
		c0.767,4.605,1.846,9.042,3.238,13.31c1.389,4.269,3.381,8.322,5.971,12.158c0.958,1.438,2.037,2.805,3.238,4.1
		c1.198,1.295,2.612,2.471,4.245,3.525V105.75v-6.907v-2.302V70.93v-2.878V34.24z M113.266,131.649
		c2.302,0.96,4.604,1.416,6.906,1.367c2.302-0.047,4.555-0.454,6.762-1.223c-0.097-3.069-0.143-6.065-0.143-8.993
		c0-2.925-0.05-5.875-0.145-8.848h-14.675v16.833c0.191,0.193,0.381,0.338,0.576,0.432
		C112.737,131.314,112.978,131.458,113.266,131.649z"
          />
          <path
            d="M175.566,52.37c0.191,1.15,0.072,2.229-0.359,3.237c-0.432,1.008-1.056,1.896-1.871,2.662
		c-0.816,0.769-1.777,1.345-2.878,1.728c-1.104,0.384-2.183,0.48-3.236,0.288c-1.92-0.383-3.646-1.486-5.181-3.31
		c-0.769-0.958-1.51-1.894-2.229-2.806c-0.721-0.91-1.608-1.75-2.662-2.518c-1.248-0.863-2.399-1.102-3.453-0.719
		c-1.057,0.384-1.776,1.391-2.158,3.022c-0.097,0.193-0.122,0.384-0.072,0.575c0.047,0.194-0.071,0.288-0.359,0.288
		c-0.193,0.097-0.337,0.05-0.432-0.144c-0.097-0.191-0.193-0.383-0.288-0.576c-1.727-2.302-2.302-4.748-1.727-7.337
		c0.479-1.917,1.677-3.285,3.597-4.102c1.918-0.813,3.741-0.694,5.468,0.36c1.245,0.769,2.229,1.679,2.949,2.734
		c0.72,1.057,1.559,2.064,2.518,3.021c1.246,1.345,2.566,2.471,3.957,3.381c1.39,0.913,3.044,1.463,4.964,1.655
		c0.767,0,1.414-0.144,1.942-0.432C174.581,53.089,175.084,52.755,175.566,52.37z M174.702,66.758
		c-2.589,0.864-4.316,2.23-5.179,4.101c-0.864,1.871-1.295,3.957-1.295,6.259c-0.096,8.251-0.169,16.475-0.216,24.676
		c-0.049,8.202-0.072,16.427-0.072,24.675c0,1.536,0.335,2.878,1.007,4.029c0.67,1.151,1.534,2.158,2.59,3.022
		c0.191,0.193,0.36,0.312,0.504,0.359c0.143,0.05,0.166,0.169,0.071,0.36c0,0.193-0.24,0.383-0.719,0.576
		c-0.672,0.288-1.295,0.382-1.871,0.288c-0.576-0.097-1.2-0.241-1.871-0.432c-1.057-0.382-2.014-0.838-2.877-1.366
		c-0.864-0.527-1.776-1.03-2.734-1.511c-2.589-1.245-5.036-1.39-7.337-0.432h-0.431c0-0.382,0.119-0.623,0.359-0.719
		c0.239-0.094,0.454-0.239,0.647-0.432c2.109-1.629,3.166-3.788,3.166-6.474c0.095-4.893,0.119-9.735,0.072-14.532
		c-0.05-4.796-0.072-9.641-0.072-14.532v-20c0-2.396-0.288-4.676-0.863-6.834c-0.576-2.159-1.68-4.195-3.309-6.115v-0.288
		c0.191-0.19,0.576-0.094,1.15,0.288c4.508,2.877,9.496,4.222,14.964,4.029c0.766,0,1.511,0,2.23,0
		C173.336,65.751,174.031,66.088,174.702,66.758z"
          />
          <path
            d="M199.594,60.715c-2.302,2.975-3.453,6.524-3.453,10.647v43.309c0,0.48,0.288,1.222,0.863,2.23
		c0.576,1.007,1.245,2.014,2.014,3.021c0.767,1.007,1.534,1.895,2.303,2.662c0.766,0.769,1.342,1.151,1.726,1.151
		c0.288,0,0.814-0.36,1.583-1.079c0.767-0.719,1.533-1.559,2.302-2.519c0.767-0.957,1.439-1.917,2.015-2.877
		c0.576-0.958,0.864-1.677,0.864-2.159c-0.098-5.754-0.169-11.46-0.217-17.121c-0.048-5.659-0.024-11.367,0.072-17.122
		c0.095-2.205-0.215-4.245-0.934-6.115c-0.721-1.871-1.752-3.619-3.095-5.251c-0.096-0.191-0.24-0.382-0.431-0.576
		c-0.289-0.479-0.338-0.814-0.145-1.007c0.191-0.191,0.336-0.215,0.433-0.072c0.095,0.145,0.238,0.265,0.431,0.36
		c1.534,1.056,3.188,1.488,4.963,1.294c1.774-0.191,3.238-1.007,4.389-2.446c2.014-2.301,4.604-3.979,7.769-5.036
		c0.382-0.191,0.624-0.191,0.72,0c0.191,0.193,0.238,0.312,0.144,0.36c-0.097,0.049-0.193,0.168-0.288,0.358
		c-1.535,1.824-2.614,3.791-3.237,5.899c-0.625,2.112-0.985,4.317-1.079,6.619c0,1.345,0.023,2.662,0.071,3.957
		c0.048,1.295,0.072,2.615,0.072,3.957c0,3.262,0,6.237,0,8.921c0,2.687,0,5.324,0,7.914c0,2.59,0,5.204,0,7.841
		c0,2.639-0.05,5.589-0.144,8.848c0,0.576,0.19,1.008,0.575,1.296c0.67,0.575,1.198,1.2,1.583,1.869
		c0.382,0.672,0.72,1.393,1.008,2.159c0.19,0.576,0.381,1.345,0.575,2.303c0,0.384-0.097,0.672-0.287,0.863
		c-0.194,0.097-0.337,0.072-0.432-0.071c-0.097-0.145-0.193-0.264-0.288-0.36c-0.672-0.67-1.438-1.151-2.302-1.439
		c-0.576-0.19-1.08-0.263-1.511-0.216c-0.432,0.05-0.888,0.36-1.367,0.936c-0.863,0.96-2.015,2.064-3.453,3.309
		c-1.439,1.248-2.877,2.399-4.317,3.453c-1.438,1.057-2.732,1.967-3.884,2.734c-1.152,0.769-1.871,1.15-2.159,1.15
		c-0.576,0-1.416-0.382-2.518-1.15c-1.104-0.767-2.255-1.727-3.453-2.878c-1.201-1.151-2.374-2.35-3.525-3.598
		c-1.151-1.245-2.064-2.349-2.734-3.309c-0.385-0.576-0.863-0.623-1.438-0.145c-1.824,1.439-3.669,2.399-5.54,2.878
		c-1.871,0.482-3.91,0.672-6.115,0.575c-0.384,0-0.576-0.093-0.576-0.288c0-0.19,0.144-0.381,0.432-0.575
		c1.439-0.67,2.59-1.559,3.453-2.662c0.863-1.102,1.677-2.325,2.446-3.67c0.382-0.478,0.576-1.15,0.576-2.014v-9.64
		c0-5.083,0.022-10.094,0.072-15.036c0.046-4.938,0.071-9.903,0.071-14.892c0-3.453-1.344-6.282-4.028-8.489
		c-0.672-0.479-1.008-1.006-1.008-1.582c2.206,0.576,4.604,0.624,7.194,0.143c1.63-0.381,3.141-0.958,4.532-1.726
		c1.39-0.767,2.757-1.677,4.101-2.734c0.669-0.67,1.414-1.246,2.23-1.726C198.034,61.675,198.825,61.196,199.594,60.715z"
          />
          <path
            d="M263.622,67.765c0.094,1.248-0.122,2.303-0.648,3.166c-0.528,0.863-1.176,1.632-1.942,2.303
		c-0.481,0.576-0.72,1.247-0.72,2.014v10.936c0,2.014,0.67,3.55,2.015,4.604c0.094,0.194,0.191,0.337,0.288,0.432
		c0.094,0.097,0.094,0.24,0,0.431c-0.097,0.288-0.337,0.338-0.72,0.145c-1.631-0.191-3.093-0.022-4.388,0.503
		c-1.295,0.528-2.567,1.272-3.813,2.229c-2.11,1.633-4.245,3.166-6.401,4.605c-2.159,1.438-4.341,2.927-6.547,4.46
		c-0.769,0.481-1.15,1.151-1.15,2.014c0.093,2.303,0.119,4.582,0.071,6.835c-0.049,2.254-0.025,4.484,0.072,6.69
		c0.191,3.071,1.295,5.564,3.31,7.482c1.821,1.726,3.74,1.92,5.754,0.576c1.917-1.246,2.541-3.069,1.871-5.468
		c-0.193-0.766-0.456-1.51-0.791-2.229c-0.337-0.719-0.841-1.415-1.51-2.086c-0.288-0.288-0.338-0.479-0.145-0.576
		c0.095-0.095,0.288-0.145,0.576-0.145c1.246,0.384,2.325,0.36,3.238-0.071c0.911-0.433,1.798-1.03,2.662-1.798
		c0.766-0.768,1.629-1.39,2.589-1.871c0.958-0.479,1.964-0.814,3.021-1.008c0.958-0.094,1.246,0.241,0.864,1.008
		c-1.536,2.974-2.014,6.043-1.438,9.208c0.19,1.057,0.55,2.064,1.079,3.021c0.526,0.96,1.367,1.727,2.518,2.303
		c0.382,0.193,0.479,0.359,0.288,0.503c-0.193,0.144-0.385,0.312-0.576,0.503c-2.495,3.166-5.755,5.133-9.784,5.899
		c-2.686,0.478-5.179,0.381-7.482-0.288c-1.057-0.288-2.014-0.72-2.877-1.295c-1.632-1.246-3.334-2.325-5.108-3.238
		c-1.776-0.91-3.669-1.511-5.683-1.798c-0.96-0.191-1.895-0.288-2.805-0.288c-0.914,0-1.849,0.193-2.807,0.575
		c-0.481,0.288-0.769,0.288-0.863,0c-0.19-0.19-0.097-0.478,0.288-0.863c2.972-2.684,4.461-6.042,4.461-10.071V75.391
		c0-0.958-0.288-1.871-0.864-2.734c-0.481-0.767-0.841-1.533-1.079-2.302c-0.241-0.767-0.457-1.534-0.648-2.303
		c0-0.191-0.024-0.407-0.071-0.646c-0.05-0.238,0.071-0.455,0.359-0.648c0.288-0.191,0.503-0.215,0.648-0.072
		c0.143,0.145,0.31,0.313,0.503,0.505c0.382,0.384,0.813,0.671,1.295,0.862c0.479,0.193,1.007,0.385,1.583,0.576
		c0.288,0,0.576-0.143,0.864-0.431c0.862-1.439,2.061-2.59,3.597-3.453c1.533-0.864,3.188-1.462,4.963-1.799
		c1.774-0.335,3.572-0.407,5.396-0.217c1.821,0.194,3.5,0.672,5.036,1.44c1.533,0.769,2.828,1.679,3.885,2.734
		c1.439,1.247,3.069,1.871,4.893,1.871C261.701,68.87,262.661,68.534,263.622,67.765z M239.737,100.139
		c1.917-1.342,3.788-2.662,5.612-3.957c1.821-1.294,3.691-2.612,5.611-3.957c1.054-0.67,1.583-1.677,1.583-3.022
		c0.094-2.302,0.071-4.604-0.072-6.906c-0.144-2.302-0.215-4.604-0.215-6.906c0-1.63-0.265-2.756-0.792-3.382
		c-0.529-0.623-1.51-1.126-2.95-1.511c-1.439-0.382-2.783-0.334-4.029,0.145c-1.248,0.48-2.159,1.294-2.733,2.445
		c-0.482,0.864-0.864,1.798-1.151,2.806c-0.288,1.007-0.481,2.039-0.576,3.093c-0.288,2.111-0.409,4.198-0.36,6.259
		c0.047,2.064,0.072,4.151,0.072,6.259c-0.097,1.344-0.121,2.711-0.072,4.101C239.712,96.998,239.737,98.509,239.737,100.139z"
          />
          <path
            d="M335.704,120.858c0,1.631,0.288,3.143,0.863,4.532c0.575,1.392,0.958,2.855,1.151,4.388
		c0.382,3.741,0.094,7.289-0.863,10.647c-0.96,3.356-3.118,5.993-6.475,7.914c-2.015,1.15-4.173,1.871-6.475,2.158
		c-2.303,0.288-4.558,0.094-6.764-0.576c-2.207-0.672-4.222-1.87-6.043-3.598c-1.822-1.726-3.165-4.028-4.029-6.905
		c-0.768-2.496-1.006-4.915-0.718-7.267c0.288-2.35,1.245-4.482,2.877-6.403c1.343-1.63,3.044-2.756,5.108-3.381
		c2.061-0.623,4.004-0.551,5.827,0.216c1.342,0.576,2.349,1.488,3.021,2.734c0.576,0.96,0.288,1.679-0.862,2.159
		c-3.453,1.344-4.942,3.646-4.461,6.906c0.288,3.166,2.062,5.13,5.323,5.898c1.439,0.383,2.854,0.238,4.245-0.431
		c1.39-0.672,2.468-1.727,3.237-3.166c2.109-3.356,2.396-6.763,0.863-10.216c-1.247-2.59-2.855-4.771-4.82-6.547
		c-1.967-1.774-4.582-2.9-7.841-3.38c1.917-1.342,3.069-2.9,3.453-4.676c0.382-1.774,0.623-3.572,0.72-5.396
		c0.094-5.659,0.119-11.271,0.072-16.834c-0.051-5.562-0.169-11.173-0.361-16.834c-0.096-1.245-0.144-2.445-0.144-3.597
		c0-1.151-0.05-2.301-0.144-3.453c-0.193-2.205-0.744-4.148-1.654-5.827c-0.913-1.678-2.039-3.332-3.382-4.964
		c-0.193-0.191-0.337-0.432-0.431-0.719c-0.097-0.288-0.241-0.527-0.433-0.72l0.144-0.576c3.933,3.36,7.818,4.82,11.655,4.389
		c3.835-0.432,7.769-2.086,11.798-4.964c-1.92,2.783-3.215,5.611-3.885,8.489c-0.672,2.877-0.959,5.852-0.863,8.92
		c0.191,8.538,0.288,17.027,0.288,25.468C335.704,103.688,335.704,112.225,335.704,120.858z M375.703,133.375
		c0.576,0.193,0.814,0.481,0.719,0.863c-0.191,0.479-0.527,0.576-1.006,0.288c-1.633-0.767-3.262-1.079-4.893-0.935
		c-1.631,0.144-3.262,0.456-4.891,0.935c-2.4,0.576-4.654,1.414-6.764,2.518c-2.111,1.102-4.125,2.325-6.043,3.67
		c-0.385-0.481-0.504-0.841-0.359-1.08c0.143-0.241,0.311-0.457,0.504-0.647c2.014-3.454,3.021-7.145,3.021-11.079
		c0-0.67,0-1.943,0-3.813c0-1.869-0.025-4.05-0.072-6.546c-0.049-2.493-0.098-5.13-0.145-7.913
		c-0.049-2.781-0.072-5.395-0.072-7.841s0-4.555,0-6.331c0-1.774,0-2.95,0-3.525c0-1.151-0.576-1.677-1.727-1.583
		c-2.111,0-4.221,0.024-6.33,0.072c-2.111,0.049-4.27,0.024-6.475-0.072c-0.482,0-0.816,0.05-1.008,0.144v30.648h-1.582V62.298
		h1.582v21.582c0.383,0.194,0.814,0.241,1.295,0.145c2.109-0.095,4.195-0.145,6.26-0.145c2.061,0,4.146,0,6.258,0
		c1.15,0,1.727-0.575,1.727-1.726c0-4.892,0-9.734,0-14.532c0-4.795,0.047-9.641,0.145-14.532c0.094-3.069-0.215-6.043-0.936-8.92
		c-0.719-2.878-1.941-5.562-3.668-8.058c-1.25-1.726-2.855-3.116-4.82-4.172c-1.969-1.055-4.029-1.75-6.188-2.086
		c-2.158-0.336-4.316-0.264-6.475,0.215c-2.158,0.481-4.006,1.392-5.54,2.734c-2.207,2.014-3.286,4.269-3.237,6.762
		c0.048,2.495,1.127,4.172,3.237,5.036c0.864,0.385,1.821,0.431,2.878,0.144c1.055-0.287,1.965-0.766,2.734-1.438
		c0.767-0.67,1.244-1.486,1.439-2.446c0.189-0.957,0.189-1.917,0-2.877c-0.195-0.191-0.242-0.334-0.145-0.431
		c0-0.479-0.193-0.911-0.576-1.295c-0.385-0.382-0.337-0.72,0.145-1.008c0.381-0.288,0.813-0.31,1.295-0.071
		c0.479,0.24,0.957,0.503,1.438,0.791c0.671,0.385,1.271,0.959,1.8,1.727c0.526,0.769,0.981,1.655,1.366,2.662
		c0.383,1.007,0.598,2.086,0.648,3.238c0.045,1.15-0.121,2.255-0.504,3.309c-0.863,2.496-2.112,4.198-3.741,5.108
		c-1.633,0.912-3.741,1.607-6.331,2.086c-3.358,0.576-6.309-0.046-8.85-1.871c-2.541-1.82-3.908-4.41-4.1-7.769
		c-0.193-4.029,0.646-7.482,2.518-10.36c1.871-2.877,4.725-4.892,8.561-6.042c4.795-1.439,9.375-1.727,13.742-0.863
		c4.363,0.863,8.463,2.974,12.303,6.331c0.477,0.481,0.908,1.007,1.293,1.583c0.383,0.576,0.814,1.104,1.295,1.583
		c1.918,2.302,4.004,4.245,6.26,5.827c2.252,1.583,4.914,2.614,7.986,3.094c1.15,0.097,2.348,0,3.596-0.288
		c0.191,0,0.406-0.071,0.646-0.216c0.24-0.144,0.455-0.072,0.648,0.216c0.191,0.288,0.191,0.625,0,1.007
		c-0.193,0.385-0.385,0.672-0.574,0.864c-1.729,1.824-3.791,3.166-6.188,4.029c-0.77,0.385-1.273,0.769-1.512,1.151
		c-0.24,0.385-0.359,0.959-0.359,1.726v67.624c0,1.824,0.311,3.407,0.936,4.749c0.623,1.344,1.367,2.496,2.23,3.453
		c0.863,0.96,1.727,1.727,2.588,2.303C374.553,132.8,375.223,133.185,375.703,133.375z"
          />
          <path
            d="M453.111,131.361c0,0.288-0.193,0.432-0.574,0.432c-2.209,0-4.342-0.263-6.404-0.792c-2.064-0.526-4.006-1.511-5.826-2.95
		c-0.576-0.478-1.105-0.431-1.582,0.144c-1.439,1.633-3.023,2.975-4.75,4.029c-1.727,1.057-3.693,1.776-5.898,2.158
		c-4.797,0.863-8.92-0.145-12.373-3.022c-3.453,2.877-7.578,3.885-12.375,3.022c-2.207-0.382-4.172-1.102-5.898-2.158
		c-1.727-1.055-3.311-2.396-4.748-4.029c-0.48-0.575-1.008-0.622-1.584-0.144c-1.822,1.439-3.766,2.424-5.826,2.95
		c-2.063,0.529-4.197,0.792-6.402,0.792c-0.385,0-0.576-0.144-0.576-0.432c0-0.288,0.143-0.431,0.432-0.431
		c1.439-0.67,2.59-1.558,3.453-2.662c0.863-1.102,1.678-2.325,2.445-3.669c0.383-0.67,0.576-1.389,0.576-2.158v-14.676
		c0-4.986,0.023-9.95,0.072-14.892c0.047-4.938,0.07-9.95,0.07-15.036c0-3.356-1.342-6.137-4.027-8.345
		c-0.672-0.479-1.008-1.055-1.008-1.727c2.207,0.672,4.605,0.72,7.195,0.145c3.164-0.67,6.043-2.158,8.633-4.46
		c0.67-0.576,1.414-1.127,2.23-1.655c0.813-0.526,1.604-1.029,2.373-1.51c-1.248,1.631-2.137,3.334-2.662,5.107
		c-0.527,1.777-0.791,3.622-0.791,5.54v47.913c0,2.016,0.863,3.598,2.59,4.748c1.918,1.248,3.908,1.536,5.971,0.864
		c2.063-0.67,3.525-2.014,4.389-4.029c0.191-0.479,0.336-0.935,0.432-1.367c0.094-0.432,0.191-0.885,0.289-1.366
		c-0.098-2.494-0.145-4.965-0.145-7.41c0-2.446,0-4.915,0-7.41V80.427c0.094-2.206-0.217-4.245-0.936-6.115s-1.75-3.62-3.094-5.251
		l-0.432-0.433c-0.287-0.479-0.338-0.813-0.145-1.006c0.191-0.192,0.336-0.216,0.434-0.073c0.094,0.145,0.236,0.266,0.43,0.36
		c1.535,1.057,3.189,1.464,4.965,1.223c1.773-0.238,3.236-1.03,4.389-2.374c2.014-2.302,4.604-3.979,7.77-5.036
		c0.383-0.191,0.623-0.239,0.719-0.144c0.193,0.193,0.238,0.336,0.145,0.431c-0.096,0.097-0.193,0.241-0.287,0.432
		c-1.535,1.823-2.615,3.79-3.238,5.898c-0.625,2.112-0.984,4.317-1.078,6.619c0,1.345,0.021,2.662,0.07,3.957
		s0.072,2.615,0.072,3.957c-0.096,5.853-0.096,11.654,0,17.41c0.096,5.756,0.096,11.561,0,17.411c0,1.056,0.191,1.967,0.576,2.733
		c0.863,2.015,2.35,3.358,4.461,4.029c2.107,0.672,4.074,0.384,5.898-0.864c1.727-1.15,2.59-2.732,2.59-4.748
		c0-8.057,0.023-16.042,0.072-23.956c0.047-7.914,0.023-15.899-0.072-23.957c0-1.917-0.266-3.763-0.791-5.54
		c-0.529-1.772-1.416-3.476-2.662-5.107c0.768,0.481,1.559,0.984,2.375,1.51c0.813,0.528,1.557,1.08,2.23,1.655
		c2.492,2.207,5.369,3.693,8.633,4.46c2.492,0.576,4.938,0.528,7.338-0.145c-0.098,0.481-0.242,0.841-0.432,1.079
		c-0.193,0.241-0.385,0.457-0.576,0.648c-2.783,2.302-4.172,5.085-4.172,8.345c0,5.086,0.021,10.097,0.07,15.036
		c0.047,4.942,0.072,9.906,0.072,14.892v14.676c0,0.769,0.191,1.488,0.576,2.158c0.766,1.344,1.605,2.567,2.518,3.669
		c0.91,1.104,2.037,1.992,3.381,2.662C452.969,130.93,453.111,131.073,453.111,131.361z"
          />
          <path
            d="M457.715,85.606c0.865-0.479,1.656-1.055,2.373-1.727c0.721-0.669,1.318-1.485,1.799-2.445
		c0.768-1.342,1.152-2.684,1.152-4.029c0-2.109-0.912-3.548-2.734-4.317c-2.975-1.245-4.314-3.26-4.029-6.043
		c1.344,0.96,2.592,1.392,3.742,1.295c1.246-0.191,2.158-0.576,2.732-1.15c1.533-1.534,3.285-2.685,5.252-3.454
		c1.967-0.767,3.979-1.151,6.043-1.151c2.063,0,4.102,0.36,6.115,1.079c2.016,0.719,3.885,1.752,5.611,3.093
		c0.48,0.288,0.863,0.6,1.152,0.936c0.287,0.336,0.623,0.696,1.006,1.079c0.865,0.862,1.822,1.006,2.879,0.431
		c0.287-0.191,0.551-0.31,0.791-0.36c0.238-0.047,0.504-0.166,0.791-0.359c0.287-0.288,0.525-0.383,0.719-0.288
		c0.191,0.097,0.289,0.384,0.289,0.863c-0.098,1.535-0.863,2.783-2.303,3.74c-0.48,0.384-0.719,0.913-0.719,1.583
		c0.094,7.867,0.143,15.708,0.143,23.525c0,7.82,0,15.661,0,23.525c0,1.631,0.67,3.333,2.016,5.107
		c1.342,1.776,2.828,2.903,4.461,3.381c0.189,0.097,0.406,0.144,0.646,0.144c0.238,0,0.359,0.144,0.359,0.432
		c0,0.384-0.24,0.672-0.719,0.863c-1.535,1.151-3.504,1.583-5.9,1.295c-1.535-0.191-2.973-0.503-4.316-0.935
		c-1.344-0.432-2.59-1.173-3.74-2.23c-0.289-0.288-0.625-0.238-1.008,0.144c-1.248,1.345-2.639,2.375-4.172,3.094
		c-1.535,0.719-3.215,1.176-5.035,1.367c-0.48,0.096-1.008,0.193-1.584,0.288c-0.576,0.097-1.104,0.19-1.582,0.287
		c-2.209,0.191-4.172-0.166-5.9-1.079c-1.725-0.911-3.262-2.181-4.602-3.813c-1.346-1.63-2.377-3.429-3.096-5.396
		c-0.719-1.964-1.176-4.05-1.365-6.259c-0.289-2.781-0.338-5.539-0.145-8.273c0.191-2.733,0.814-5.443,1.871-8.129
		c1.916-4.892,5.178-8.872,9.783-11.942c1.918-1.245,3.813-2.493,5.682-3.741c1.871-1.245,3.764-2.446,5.686-3.597
		c1.342-0.767,2.014-1.917,2.014-3.453v-5.179c0-0.576-0.145-1.008-0.432-1.296c-0.77-0.766-1.561-1.366-2.375-1.798
		c-0.814-0.431-1.797-0.598-2.949-0.503c-1.057,0.097-1.822,0.553-2.301,1.367c-0.48,0.816-0.434,1.704,0.143,2.662
		c1.246,2.111,1.559,3.91,0.936,5.396c-0.625,1.488-1.799,2.855-3.525,4.1c-3.357,2.302-6.955,3.119-10.789,2.446
		c-0.193-0.094-0.338-0.167-0.434-0.216C458.051,85.919,457.906,85.8,457.715,85.606z M479.154,89.923
		c-1.633,0.672-3.119,1.535-4.461,2.59c-8.441,7.1-11.559,16.211-9.354,27.336c0.191,1.345,0.576,2.615,1.152,3.813
		c0.576,1.2,1.391,2.23,2.445,3.093c0.576,0.481,1.199,0.914,1.871,1.295c0.67,0.384,1.389,0.482,2.158,0.288
		c1.918-0.288,3.428-1.079,4.531-2.374c1.102-1.295,1.656-2.9,1.656-4.819V93.089V89.923z"
          />
          <path
            d="M539.152,154.958c0.957,0.862,1.678,1.871,2.158,3.021c0.479,1.151,0.91,2.303,1.295,3.453
		c0.191,0.479,0.143,0.768-0.145,0.864c-0.289,0.095-0.527-0.096-0.719-0.576c-1.438-1.438-3.189-2.158-5.252-2.158
		s-3.766,0.767-5.107,2.303c-0.674,0.766-1.248,1.295-1.727,1.583c-2.811,1.533-5.691,2.541-8.645,3.021
		c-2.953,0.479-5.973,0.145-9.053-1.007c-2.783-1.057-4.988-2.734-6.619-5.036c-1.633-2.301-2.445-4.844-2.445-7.625
		c0-1.345,0.264-2.64,0.791-3.885c0.525-1.248,1.244-2.352,2.158-3.31c0.91-0.96,1.941-1.752,3.094-2.374
		c1.15-0.625,2.396-0.985,3.74-1.08c1.246-0.097,2.449-0.072,3.613,0.072c1.162,0.144,2.277,0.454,3.344,0.935
		c1.451,0.576,2.418,1.534,2.902,2.877c0.484,1.055,0.193,1.583-0.871,1.583c-1.551,0.191-2.908,0.624-4.066,1.296
		c-1.066,0.669-1.889,1.461-2.469,2.374c-0.58,0.91-0.775,1.893-0.582,2.948c0.193,0.864,0.727,1.606,1.598,2.231
		c0.873,0.622,1.889,1.029,3.053,1.222c2.518,0.288,4.939-0.336,7.266-1.871c1.646-0.862,2.471-2.351,2.471-4.46v-8.345
		c0-1.439-0.023-2.855-0.07-4.245c-0.051-1.392-0.072-2.805-0.072-4.245c0-0.191,0.021-0.335,0.072-0.431
		c0.047-0.095-0.025-0.239-0.217-0.432h-0.143c-1.152,1.342-2.473,2.277-3.957,2.806c-1.488,0.526-3,0.741-4.533,0.647
		c-1.535-0.097-3.021-0.432-4.461-1.007c-1.438-0.576-2.732-1.294-3.883-2.158c-0.961-0.669-1.896-1.342-2.807-2.014
		c-0.912-0.67-1.895-1.198-2.949-1.583c-1.057-0.382-2.111-0.598-3.166-0.648c-1.057-0.047-2.158,0.216-3.309,0.792
		c0.191-1.533,0.646-2.853,1.367-3.957c0.719-1.102,1.604-2.036,2.66-2.805c0.67-0.288,1.008-0.864,1.008-1.727V78.7
		c0-1.727-0.242-3.309-0.719-4.748c-0.482-1.439-1.391-2.734-2.734-3.884c-0.385-0.383-0.48-0.671-0.287-0.864
		c0.094-0.288,0.479-0.431,1.15-0.431c0.576,0.193,1.125,0.336,1.654,0.431c0.527,0.097,1.127,0.05,1.799-0.143
		c2.303-0.479,4.365-1.318,6.188-2.518c1.82-1.198,3.547-2.613,5.18-4.245c0.191-0.095,0.479-0.335,0.863-0.719
		c0.189-0.191,0.359-0.407,0.504-0.648c0.143-0.238,0.359-0.216,0.646,0.072c0.191,0.193,0.217,0.409,0.072,0.648
		c-0.145,0.24-0.313,0.456-0.504,0.647c-1.102,1.344-1.879,2.782-2.328,4.316c-0.449,1.535-0.826,3.118-1.125,4.748
		c-0.385,2.014-0.529,4.029-0.432,6.043c0.094,2.014,0.145,4.078,0.145,6.187v42.589c0,0.769,0.266,1.463,0.799,2.086
		c0.531,0.625,1.236,1.032,2.107,1.223c0.873,0.193,1.721,0.097,2.543-0.288c0.822-0.382,1.43-0.911,1.818-1.583
		c0.289-0.767,0.24-1.559-0.146-2.375c-0.389-0.813-1.066-1.414-2.033-1.798c-0.293-0.094-0.656-0.144-1.092-0.144
		s-0.703-0.238-0.801-0.719c0-0.957,0.582-1.871,1.746-2.734c1.258-0.862,2.496-1.198,3.707-1.007
		c1.209,0.193,2.445,0.625,3.707,1.295c0.287,0.194,0.602,0.41,0.941,0.648c0.34,0.24,0.654,0.457,0.947,0.648
		c0.191-0.191,0.264-0.336,0.215-0.433c-0.049-0.094-0.072-0.238-0.072-0.431c0-5.469-0.023-10.936-0.07-16.403
		c-0.051-5.468-0.072-10.982-0.072-16.547c0.094-1.917,0.094-3.813,0-5.683c-0.098-1.871,0.047-3.763,0.432-5.684
		c0.479-2.205,1.246-4.101,2.303-5.683c1.053-1.583,2.732-2.709,5.035-3.38c1.15-0.288,2.324-0.505,3.523-0.648
		c1.199-0.144,2.375-0.073,3.527,0.215c0.287,0.097,0.549,0.193,0.791,0.288c0.238,0.097,0.502,0.288,0.791,0.576
		c-2.014,0.48-3.502,1.439-4.461,2.877c-0.768,1.057-1.293,2.208-1.582,3.454c-0.287,1.248-0.48,2.542-0.576,3.884
		c-0.096,3.935-0.096,7.82,0,11.655c0.096,3.838,0.096,7.723,0,11.654c-0.193,6.139-0.266,12.254-0.215,18.346
		c0.047,6.092,0.072,12.208,0.072,18.344v2.303v1.007v1.871v10.215C538.434,153.901,538.672,154.477,539.152,154.958z"
          />
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "logo",
};
</script>

<style scoped>
.svg-container {
  width: 100vw;
}
.svg-group {
    display: flex;
	flex-direction: column;
	justify-content: space-around;
}
.s-svg {
	justify-self: center;
  width: 100vw;
  max-width: 600px;
  margin: auto;
}
.s-logo {
  fill: url(#gradient);  
  filter: url(#blur);
}
.give-away g {
    fill: url(#inv-gradient);  
  filter: url(#blur);
}
.give-away {
  width: 50vw;
  max-width: 300px;
  margin-top: -20px;
}
</style>