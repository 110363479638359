<template>
  <div>
    <logo class="s-logo-align" />
    <div class="giveaway">
      <transition name="fade" mode="out-in">
      
      <div v-if="!submitted" key="1" class="scroll">
        <div class="form" @submit.prevent="submitUser">
          <div class="form-group">
            <label for="name">Name:</label>
            <input
              type="text"
              class="form-input"
              id="name"
              v-model="user.name"
              name="name"
            />
            <div class="error" v-if="v$.user.name.$error">
              Please input a name
            </div>
          </div>

          <div class="form-group">
            <label for="email">E-mail:</label>
            <input
              class="form-input"
              id="email"
              v-model="user.email"
              name="email"
            />
            <div class="error" v-if="v$.user.email.$error">
              That's not a valid email
            </div>
          </div>
          <button
            @click="v$.user.$touch"
            v-on:click="submitUser"
            :disabled="v$.$error"
            class="submit"
          >
            Submit
          </button>
          <div class="disclaimer">
            By pressing submit you agree to allow Fellpeller Studios to email
            you updates about Saturnalia and future projects
          </div>
        </div>
        <div class="rules">RULES</div>
        <div class="rule">
          <p>
            <span class="capital">O</span>ne entry per person. <br /><span
              class="capital"
              >T</span
            >he prizes are as follows:
          </p>
          <p><br /></p>
          <p>
            -<span class="capital">A</span> custom pair of "<span class="capital"
              >S</span
            >aturnalia" themed <span class="capital">N</span>ike shoes
          </p>
          <p>
            -<span class="capital">A</span> set of "<span class="capital">S</span
            >aturnalia" drinking glasses
          </p>
          <p>
            -<span class="capital">A</span> custom "<span class="capital">S</span
            >aturnalia" hoodie
          </p>
          <p>
            -<span class="capital">A</span> custom "<span class="capital">S</span
            >aturnalia" t-shirt
          </p>
          <p>
            -<span class="capital">A</span> signed "<span class="capital">S</span
            >aturnalia" poster
          </p>
          <p><br /></p>
          <p>
            <span class="capital">E</span>ach entry comes with a free ticket.
            <br /><span class="capital">Y</span>ou must show up to the premiere
            to collect your ticket and your entry
          </p>
          <p>
            <span class="capital">T</span>he premiere begins at 7pm on December
            16th, 2021, at the <span class="capital">M</span>ichigan
            <span class="capital">T</span>heater in
            <span class="capital">A</span>nn
            <span class="capital">A</span>rbor.<br /><span class="small"
              >(all of this information will also be sent to you in an email
              upon entering)</span
            >
          </p>
          <p><br /></p>
          <p><br /></p>
          <p><br /></p>
        </div>
      </div>
      <div v-else key="2" class="thanks"><span style="font-family: Saturnalia; font-size: 40pt">Thank you.</span><br>An email with all of the details of the premiere has been sent to you.</div>
      </transition>
    </div>

    <flame class="candle-container" />
  </div>
</template>

<script>
import Flame from "./Flame";
import Logo from "./SaturnaliaLogo.vue";
import saturnaliaGiveaway from "../../services/SaturnaliaGiveaway";
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import emailjs from "emailjs-com";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  name: "saturnalia-giveaway",
  data() {
    return {
      user: {
        name: "",
        email: "",
      },
      submitted: false,
    };
  },
  components: {
    flame: Flame,
    logo: Logo,
  },
  validations() {
    return {
      user: {
        name: {
          required,
        },
        email: {
          required,
          email,
        },
      },
    };
  },
  methods: {
    submitUser() {
      var data = {
        name: this.user.name,
        email: this.user.email,
      };
      if (!this.v$.$error) {
        emailjs.send("service_8b4c61r", "template_f4ezdsb", {
          to_email: data.email,
          to_name: data.name,
        });
        saturnaliaGiveaway
          .create(data)
          .then(() => {
            console.log("Created new item successfully!");
            this.submitted = true;
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
  },
};
</script>

<style>
@font-face {
  font-family: Saturnalia;
  src: url("../../assets/fonts/Dark power.ttf");
}
.giveaway {
  display: inline-block;
  text-align: center;
  color: rgb(253, 253, 253);
  width: 100vw;
  position: fixed;
  min-height: 102%;
  margin-bottom: 180px;
  text-align: left;
}
.scroll {
  display: inline-block;
  height: 100vh;
  width: 100vw;
  overflow-y: scroll;
  transition: all 0.5s;
}
.rules {
  font-family: "Saturnalia";
  margin: 3vh auto 1vh auto;
  width: 80vw;
  max-width: 500px;
  color: rgba(248, 244, 244, 0.911);
  text-shadow: 0 0 10px #000;
  font-size: 50pt;
  line-height: 60pt;
  text-align: center;
  border-bottom: 3px solid rgba(255, 255, 255, 0.342);
}
.rules:active {
  text-shadow: 0 0 1px #000;
}
.rule {
  font-family: "Arial, Helvetica, sans-serif";
  margin: auto;
  font-size: 20pt;
  text-align: center;
  width: 80vw;
  max-width: 500px;
  text-shadow: 3px 0 5px #000;
  background-color: rgba(5, 5, 5, 0.226);
  box-shadow: 0 0 20px 20px rgba(5, 5, 5, 0.226);
}
.capital {
  font-family: "Saturnalia";
  font-size: 30pt;
  line-height: 20pt;
}
.small {
  font-size: 10pt;
}
.form {
  position: relative;
  font-family: "Saturnalia";
  font-size: 30pt;
  line-height: 30pt;
  width: 80vw;
  max-width: 600px;
  margin: 28vh auto auto auto;
  z-index: 3;
}
.form-input {
  font-size: 30pt;
  color: rgb(214, 23, 55);
  height: 40pt;
  width: 100%;
  background-color: rgba(141, 141, 141, 0.144);
  border: none;
  border-radius: 8pt;
  padding-left: 10px;
}
.form-input:focus {
  color: white;
  border-bottom: 2px solid white;
  outline: none;
}
.error {
  font-family: Arial, Helvetica, sans-serif;
  max-height: 1000px;
  color: rgba(207, 24, 48, 0.61);
  font-size: 15pt;
}
.submit {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.253);
  padding: 5pt;
  border-radius: 8pt;
  border: 2pt solid #ffffff;
  color: white;
  margin: auto;
}
.disclaimer {
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
  font-size: 8pt;
  line-height: 8pt;
  margin: 4pt;
  color: rgba(255, 255, 255, 0.342);
  background-color: rgba(0, 0, 0, 0.205);
}
.thanks {
  position: fixed;
  top: 0;
  width: 100%;
  color: rgb(255, 255, 255);
  margin: 28vh auto auto auto;
  padding: 40px;
  font-family:Arial, Helvetica, sans-serif;
  font-size: 20pt;
  line-height: 30pt;
  text-align: center;
  opacity: 1;
  transition: opacity 2s;
}
.s-logo-align {
  position: fixed;
  top: 0;
  margin: 25px auto 25px auto;
  z-index: -2;
  animation: flicker 10s infinite, shake 8s ease-in-out infinite;
}
.candle-container {
  position: fixed;
  height: 30vh;
  min-height: 233px;
  width: 100vw;
  bottom: 0;
  z-index: -2;
}
.candle {
  align-self: center;
  bottom: 0;
}

@keyframes flicker {
  50% {
    opacity: 0.65;
  }
}
@keyframes shake {
  0% {
    transform: rotateZ(1deg);
  }
  50% {
    transform: rotateZ(-1deg);
  }
  100% {
    transform: rotateZ(1deg);
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 1s ease;
}
.fade-enter, .fade-leave-to
/* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

@media screen and (min-height: 400px) {
}
</style>
<style>
body {
  background-color: black;
}
</style>
