import firebase from "../firebase";

const db = firebase.collection("/saturnaliaGiveaway");

class saturnaliaGiveaway {

  create(user) {
    return db.add(user);
  }
  
}

export default new saturnaliaGiveaway();